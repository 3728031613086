<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <b>Update SMS Template</b>
        </CCardHeader>
        <CCardBody>
          <CAlert
            :color="alertColor"
            closeButton
            :show.sync="showAlert"
          >
            {{ alertContent }}
          </CAlert>
          <CForm style="margin-top: 24px;">
            <CInput
              label="No."
              description="The order of this template in the list."
              horizontal
              type="number"
              v-model="pos"
            />
            <CInput
              label="Key"
              horizontal
              type="text"
              v-model="key"
              disabled
            />
            <CTextarea
              label="Value"
              horizontal
              type="textarea"
              rows="4"
              v-model="value"
            />
            <CTextarea
              label="Description"
              horizontal
              type="textarea"
              rows="4"
              v-model="description"
            />

            <br><hr>
            <div class="form-actions">
              <CButton
                color="primary"
                :disabled='submitted'
                @click="updateTemplateClicked()">
                Update Template
              </CButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'

export default {
  name: 'UpdateSmsTemplate',
  created() {
    this.getTemplate();
  },
  data () {
    return {
      pos: 1,
      key: "",
      value: "",
      description: "",

      alertColor: "",
      showAlert: false,
      alertContent: "",
      submitted: false
    }
  },
  methods: {
    getTemplate() {
      let _this = this;
      _this.submitted = true;

      AxiosBase.get("/sms_template/get", {
        params: {
          key: _this.$route.params.key
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        if (res && res.data) {
          _this.key = res.data.key;
          _this.value = res.data.value;
          _this.description = res.data.description;
          _this.pos = res.data.pos;
        }

        _this.submitted = false;
      });
    },
    updateTemplate(formData) {
      let _this = this;
      _this.submitted = true;

      AxiosBase.post("/sms_template/update", formData, { validateStatus() { return true; } })
      .catch(function(error) {
        console.log(error);

        _this.alertColor = "danger";
        _this.showAlert = true;
        _this.alertContent = "Update new SMS Template failed. Check console for detail."
        _this.submitted = false;
      })
      .then(function(res) {

        if (res && res.status === 200) {
          _this.alertColor = "success";
          _this.alertContent = "Update new SMS Template success."
        } else if (res && res.status === 409){
          _this.alertColor = "danger";
          _this.alertContent = `Update new SMS Template failed. ${res?.data?.message}. Check console for detail.`
        } else {
          console.log(res);
          _this.alertColor = "danger";
          _this.alertContent = `Create new SMS Template failed. ${JSON.stringify(res?.data)}. Check console for detail.`
        }

        _this.showAlert = true;
        _this.submitted = false;
      });
    },
    updateTemplateClicked() {
      let formData = {
        key: this.key,
        value: this.value,
        description: this.description,
        pos: this.pos
      };

      this.updateTemplate(formData);
    },
  }
}
</script>
